import { render, staticRenderFns } from "./AddContentComponent.vue?vue&type=template&id=b64604a8&scoped=true"
import script from "./AddContentComponent.vue?vue&type=script&lang=js"
export * from "./AddContentComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b64604a8",
  null
  
)

export default component.exports